import React from "react";
import { graphql } from "gatsby";

import CyclePage from "../components/cycle-page";
import SEO from "../components/seo";
import { mapEdgesToNodes } from "../lib/helpers";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query CyclePageTemplateQuery($id: String!) {
    cycle: sanityCycle(id: { eq: $id }) {
      id
      title
      theme
      description
      slug {
        current
      }
      publishedAt
    }
    contributions: allSanityContribution(
      sort: { fields: [order], order: ASC }
      filter: { slug: { current: { ne: null } }, cycle: { id: { eq: $id } } }
    ) {
      edges {
        node {
          id
          title
          authors {
            author {
              name
            }
          }
          slug {
            current
          }
          mainImage {
            ...SanityImage
          }
        }
      }
    }
  }
`;

const CyclePageTemplate = ({ data }) => {
  const cycle = data?.cycle;
  if (!cycle) return null;
  const contributions = mapEdgesToNodes(data?.contributions);
  return (
    <>
      <SEO
        title={`${cycle.title}: ${cycle.theme}`}
        description={cycle.description}
      />
      <CyclePage cycle={cycle} contributions={contributions} />
    </>
  );
};

export default CyclePageTemplate;
