import React from "react";
import { Link } from "gatsby";

import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import { boxUlCycle } from "./box-list.module.css";
import * as styles from "./cycle-page.module.css";

function CyclePage({ cycle, contributions }) {
  const hasPosts = !!contributions?.length;

  return (
    <ul className={boxUlCycle}>
      <li>
        <h1>
          <Link to="./context" className={styles.titleLink}>
            {cycle.theme}
          </Link>
          {!hasPosts && " (coming soon)"}
        </h1>
      </li>
      {hasPosts &&
        contributions.map((post) => {
          const bgUrl = imageUrlFor(buildImageObj(post.mainImage))
            .auto("format")
            .url();
          return (
            <li
              key={post.id}
              style={{
                backgroundImage: `url("${bgUrl}")`,
                backgroundPosition: `${
                  (post.mainImage.hotspot?.x ?? 0.5) * 100
                }% ${(post.mainImage.hotspot?.y ?? 0.5) * 100}%`
              }}
            >
              <Link to={post.slug.current}>
                <h2>{post.title}</h2>
                <span>
                  {post.authors.map(({ author }) => author.name).join(", ")}
                </span>
              </Link>
            </li>
          );
        })}
    </ul>
  );
}

export default CyclePage;
